import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../api/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if (localStorage.getItem('jwt')) {
      return this.authService.getAuthUser()
        .pipe(
          map(data => !!data),
          catchError(() => of(false)
          )
        );
    }

    localStorage.clear();
    this.router.navigate(['login']);
    return false;
  }
}
