import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ResponseInterface} from '../../models/response.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseUrlPlatform: string = `${environment.baseUrl}`;

  constructor(private httpClient: HttpClient) {
  }

  login(data: {username: string, password: string}): Observable<ResponseInterface<{authToken: string}>> {
    return this.httpClient.post<ResponseInterface<{authToken: string}>>(`${this.baseUrlPlatform}auth/login`, data);
  }

  getAuthUser(): Observable<ResponseInterface<{username: string; id: string}>> {
    return this.httpClient.get<ResponseInterface<{username: string; id: string}>>(`${this.baseUrlPlatform}auth/user`);
  }
}
