import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Provider} from '@angular/core';
import {AuthInterceptor} from './auth-interceptor';
import {ErrorInterceptor} from './error-interceptor';

export const httpInterceptorProviders: Provider[] = [
  // { provide: RequestCache, useClass: RequestCacheWithMap },
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
];
