import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorStatusEnum} from '../enums/error-status.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errResponse: HttpErrorResponse) => {
        switch (errResponse.status) {
          case ErrorStatusEnum.Unauthorized:
            localStorage.clear();
            window.location.href = '';
            break;
        }

        return throwError(errResponse);
      })
    );
  }
}
