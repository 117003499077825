import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlackListPipe } from '../pipes/black-list.pipe';
import { SafePipe } from '../pipes/safe.pipe';

@NgModule({
  declarations: [BlackListPipe, SafePipe],
  imports: [CommonModule],
  exports: [BlackListPipe, SafePipe]
})
export class SharedModule {}
