import {Component} from '@angular/core';
// import * as CustomEditor from '../build/ckeditor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // public Editor: any = CustomEditor;
}
