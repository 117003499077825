import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blackList'
})
export class BlackListPipe implements PipeTransform {
  blackList = [
    '055304090',
    '+37455304090',
    '37455304090',
    '041020003',
    '+37441020003',
    '37441020003'
  ];

  transform(value: string): boolean {
    return !this.blackList.some(phoneNumber => phoneNumber.includes(value));
  }

}
