import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminGuard} from './platform/guards/admin-guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth-routing.module').then(m => m.AuthRoutingModule)
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () => import('./admin-workspace/admin-workspace-routing.module').then(m => m.AdminWorkspaceRoutingModule)
      },
      {
        path: '**',
        redirectTo: 'admin',
        pathMatch: 'full',
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'admin',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
